export function toCurrency(valor) {
  return parseFloat(valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL', maximumFractionDigits: 2});
}

export function toCurrencyTransform(valor) {
  let valorSemCifrao = valor.replace(/^R\$\s?/, '');
  let newVal = parseFloat(valorSemCifrao.replace(/\./g, '').replace(',', '.'))
  let formatted = toCurrency(newVal);
  return formatted
}

export function toCurrencySave(valor) {
  return valor.replace(/^R\$\s?/, '').replace(/\./g, '').replace(',', '.');
}