<template>
  <v-row>
    <v-col cols="12">
      <v-card class="teros-elevation">
        <v-card-title class="px-5 text-uppercase v-title-cadastro">
          Remanejamento de rubricas
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row>
                <inputv class="px-5 py-0" v-for="(col, index) in cols" :key="index" :colSize="col.colSize" :label="col"
                  :opts="opts" :valid.sync="col.valid" @changed="atualizaValores($event, col)"
                  @mutate="atualizaSaldo($event)" v-model="rubricaDe[col.key]">
                </inputv>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <inputv class="px-5 py-0" v-for="(col, index) in colsTarget" :key="index" :colSize="col.colSize"
                  :label="col" :opts="opts" :valid.sync="col.valid" @changed="atualizaValoresPara($event, col)">
                </inputv>
              </v-row>
              <v-row class="px-5 justify-end">
                <v-dialog v-model="dialogRubrica" width="500" hide-overlay
                  transition="dialog-bottom-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-on="on" v-bind="attrs" depressed>+ Adicionar nova
                      rubrica</v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-item-group v-model="currentStep" class="text-center justify-content-center pt-7" mandatory>
                        <v-item v-slot="{ active }">
                          <v-btn dense text tile :style="{
                            opacity: active ? 1 : 0.8,
                            'border-bottom': currentStep >= 0 ? '5px solid green' : 'none',
                            width: 'auto',
                            padding: '0 0.5rem',
                          }" @click="currentStep = 0">
                            1 - Rubrica
                          </v-btn>
                        </v-item>
                        <v-item v-slot="{ active }">
                          <v-btn dense text tile :style="{
                            opacity: active ? 1 : 0.8,
                            'border-bottom': currentStep >= 1 ? '5px solid green' : 'none',
                            width: 'auto',
                            padding: '0 0.5rem',
                          }" @click="currentStep = 1">
                            2 - Pareamento de rubrica
                          </v-btn>
                        </v-item>
                      </v-item-group>
                      <v-window v-model="currentStep" class="mt-5">
                        <v-window-item>
                          <v-col>
                            <inputv
                              v-for="(col, index) of colsRubrica"
                              v-model="novaRubrica[col.key]"
                              :key="index"
                              :label="col"
                              :opts="opts"
                            ></inputv>
                            <v-btn @click="salvarNovaRubrica" depressed color="secondary" dark>Salvar rubrica</v-btn>
                          </v-col>
                        </v-window-item>
                        <v-window-item>
                          <v-col>
                            <inputv
                              v-for="(col, index) of colsPareamento"
                              v-model="novoPareamento[col.key]"
                              :colSize="col.colSize"
                              :key="index"
                              :label="col"
                              :opts="opts"
                            ></inputv>
                          </v-col>
                          <v-btn @click="doSaveModal" depressed color="secondary" dark>Salvar pareamento</v-btn>
                        </v-window-item>
                      </v-window>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn @click="closeDialog()" depressed dark color="primary">Cancelar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <p>Total comprovado: <b>{{ toCurrency(rubricaDe.total_realizado) }}</b></p>
                  <p>Total orçado: <b>{{ toCurrency(rubricaDe.valor_previsto) }}</b></p>
                  <p>Saldo disponível: <b>{{ toCurrency((rubricaDe.valor_previsto - rubricaDe.total_realizado)) }}</b></p>
                  <p>Valor a ser transferido: <b>{{ toCurrency(rubricaDe.valor_transferido) }}</b></p>
                  <p>Novo saldo:
                    <b :style="{
                      color: rubricaDe.saldoDisponivelPosTransf < 0 ? 'red' : 'black'
                    }">
                      {{ toCurrency(rubricaDe.saldoDisponivelPosTransf) }}
                    </b>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-text>
                  <p>Total comprovado: <b>{{ toCurrency(rubricaPara.total_realizado) }}</b></p>
                  <p>Total orçado: <b>{{ toCurrency(rubricaPara.valor_previsto) }}</b></p>
                  <p>Saldo disponível:
                    <b :style="{
                      color: (rubricaPara.valor_previsto - rubricaPara.total_realizado) < 0 ? 'red' : 'black'
                    }">
                      {{ toCurrency((rubricaPara.valor_previsto - rubricaPara.total_realizado)) }}
                    </b>
                  </p>
                  <p>Valor a receber: <b>{{ toCurrency(rubricaPara.valor_transferido) }}</b></p>
                  <p>Novo saldo: <b>{{ toCurrency(rubricaPara.saldoDisponivelPosTransf) }}</b></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-5 py-4 flex-wrap justify-content-end">
          <v-btn class="mx-2" style="width: 150px" color="secondary" depressed @click="close()">Cancelar</v-btn>
          <v-btn style="width: 200px" color="primary" depressed @click="saveRemanejamentoAndReload()" :disabled="!isValidValue">Salvar
            remanejamento</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { toCurrency, toCurrencySave, toCurrencyTransform } from '@/helpers/toCurrency';
import inputv from '@/components/input-v.vue';

export default {
  // props: {},
  components: {
    inputv,
  },
  // mixins: [],
  // directives: {},
  computed: {
    ...mapGetters(['clientId']),
    projetoId() {
      return this.$route.params.id;
    },
    rubricaSelecionada() {
      return this.$route.params.rubrica;
    },
    cols() {
      return [
        {
          name: 'De',
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: 'rubrica_id',
          name: 'Rubrica',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'rubricas', key: 'rubrica_id', name: 'rubrica' }
        },
        {
          key: 'valor',
          name: 'Valor a ser transferido',
          type: this.$fieldTypes.MONEY,
        },
      ]
    },
    colsTarget() {
      return [
        {
          name: 'Para',
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: 'rubrica_id',
          name: 'Rubrica',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'rubricas', key: 'rubrica_id', name: 'rubrica' },
          editable: (this.rubricaDe.valor_transferido > 0 && this.rubricaDe.valor_previsto > 0),
        },
      ]
    },
    colsRubrica() {
      return [
        {
          key: 'tipo',
          name: 'Tipo rubrica',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
        },
        {
          key: 'ativo',
          name: 'Status',
          type: this.$fieldTypes.SELECT,
          rel: { to:'statusRubrica', key: 'id', name: 'value'},
        },
      ]
    },
    rubricaResourceUrl() {
      return this.apiResource('/v1/captacao/tipoRubricas');
    },
    colsPareamento() {
      return [
        {
          key: 'rubrica_id',
          name: 'Rubrica',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'rubricasPareamento', key: 'id', name: 'tipo' },
          rules: [{ rule: 'required' }],
          colSize: 12
        },
        {
          key: 'aquisicao',
          name: 'Aquisição',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'aquisicoes', key: 'aquisicao', name: 'aquisicao' },
          rules: [{ rule: 'required' }],
          colSize: 12,
        },
        {
          key: 'contrapartida',
          name: 'O valor que será remanejado para a rubrica, é contrapartida?',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'contrapartida', key: 'contrapartida', name: 'contrapartida' },
          rules: [{ rule: 'required' }],
          colSize: 12,
        },
      ]
    },
    pareamentoResourceUrl() {
      return this.apiResource(`/v1/captacao/${this.clientId}/rubricas/pareamento/${this.projetoId}`);
    },
    orcamentoDetalhadoResourceUrl() {
      return this.apiResource(`v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/detalhado`);
    }
  },
  data() {
    return {
      opts: {
        rubricas: [],
        descricoes: [],
        statusRubrica: [
          {
            id: 1,
            value: 'Ativo'
          },
          {
            id: 0,
            value: 'Inativo'
          },
        ],
        rubricasPareamento: [],
        aquisicoes: [
          {
            aquisicao: 'Nacional'
          },
          {
            aquisicao: 'Importada'
          }
        ],
        contrapartida: [
          {
            contrapartida: 'Sim'
          },
          {
            contrapartida: 'Não'
          }
        ]
      },
      rubrica: [],
      rubricaDe: {
        rubrica: '',
        rubrica_id: null,
        total_realizado: 0,
        valor_previsto: 0,
        valor_transferido: 0,
        saldoDisponivelPosTransf: 0,
      },
      rubricaPara: {
        rubrica_id: null,
        total_realizado: 0,
        valor_previsto: 0,
        valor_transferido: 0,
        saldoDisponivelPosTransf: 0,
      },
      dialogRubrica: false,
      currentStep: 0,
      isValidValue: false,
      novaRubrica: {
        tipo: '',
        status: '',
      },
      novoPareamento: {},
      toCurrency,
      toCurrencyTransform,
      toCurrencySave,
    }
  },
  created() {
    this.getRubricas();
    this.getRubricasPareamento().then((result) => {
      this.opts.rubricasPareamento = result.rubricas || [];
    });
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    getRubricas() {
      this.apiResource(`/v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/comprovacao`).get().then((result) => {
        this.opts.rubricas = result;
        if (this.rubricaSelecionada) {
          this.atualizaValores(this.rubricaSelecionada);
        }
        return result;
      });
    },
    atualizaValores(valor) {
      const rubricaSelecionada = this.opts.rubricas.filter((rubrica) => rubrica.rubrica_id == valor)[0];
      this.rubricaDe.valor_previsto = rubricaSelecionada.valor_previsto;
      this.rubricaDe.total_realizado = rubricaSelecionada.total_realizado;
      this.rubricaDe.rubrica = rubricaSelecionada.rubrica;
      this.rubricaDe.rubrica_id = rubricaSelecionada.rubrica_id;
      this.rubricaDe.valor_transferido = 0;
      this.rubricaDe.saldoDisponivelPosTransf = (this.rubricaDe.valor_previsto - this.rubricaDe.total_realizado);
    },
    atualizaSaldo(valor) {
      valor = toCurrencySave(valor);
      const saldo = (this.rubricaDe.valor_previsto - this.rubricaDe.total_realizado);
      if(!this.validaValorSaldo(valor, saldo)) return;
      this.rubricaDe.valor_transferido = valor;
      this.rubricaDe.saldoDisponivelPosTransf = (saldo - this.rubricaDe.valor_transferido);
      this.isValidValue = true;
    },
    atualizaValoresPara(valor) {
      const rubricaSelecionada = this.opts.rubricas.filter((rubrica) => rubrica.rubrica_id == valor)[0];
      this.rubricaPara.valor_previsto = rubricaSelecionada.valor_previsto;
      this.rubricaPara.total_realizado = rubricaSelecionada.total_realizado;
      this.rubricaPara.valor_transferido = this.rubricaDe.valor_transferido;
      this.rubricaPara.rubrica_id = rubricaSelecionada.rubrica_id;

      const saldo = (rubricaSelecionada.valor_previsto - rubricaSelecionada.total_realizado);
      const newValue = Number.parseFloat(this.rubricaPara.valor_transferido);
      this.rubricaPara.saldoDisponivelPosTransf = (saldo + newValue).toFixed(2);
    },
    close() {
      this.$router.push({name: 'comprovacao-orcamento', params: { id: this.projetoId }});
    },
    async saveRemanejamento() {
      return await this.apiResource(`/v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/remanejamento`)
        .save({
          empresa_id: this.clientId,
          projeto_id: this.projetoId,
          rubricaDe: {
            rubrica_id: this.rubricaDe.rubrica_id,
            valor_transferido: this.rubricaDe.valor_transferido,
          },
          rubricaPara: {
            rubrica_id: this.rubricaPara.rubrica_id,
          },
        })
        .then((result) => {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: 'Remanejamento realizado',
            text: "Realizado remanejamento de rubricas com sucesso !",
          })
          this.$router.push({name: 'comprovacao-orcamento', params: { id: this.projetoId }});
          return result;
        })
    },
    closeDialog() {
      this.dialogRubrica = false;
      this.getRubricas();
    },
    async salvarNovaRubrica() {
      const resource = this.rubricaResourceUrl;
      await resource.save({ ...this.novaRubrica }).then(() => {
        this.$notify({
          group: 'geral',
          duration: 5000,
          type: 'success',
          title: 'Rubrica',
          text: 'Nova rubrica adicionada com sucesso!'
        });
        this.currentStep += 1;
      })
    },
    async salvarNovaPareamento() {
      const resource = this.pareamentoResourceUrl;
      let pareamento = { ...this.novoPareamento }
      pareamento['empresa_id'] = this.clientId;
      pareamento['projeto_id'] = this.projetoId;
      pareamento['descricoes'] = [{ descricao: 'Sem descrição', finalidade: 'Sem finalidade', aquisicao: pareamento.aquisicao }];
      return await resource.save({ ...pareamento }).then(() => {
        this.$notify({
          group: 'geral',
          duration: 5000,
          type: 'success',
          title: 'Pareamento',
          text: 'Nova pareamento adicionado com sucesso!'
        });
        return pareamento;
      })
    },
    async getRubricasPareamento() {
      const resource = this.pareamentoResourceUrl;
      return await resource.get().then((result) => {
        return result;
      })
    },
    async saveOrcamentoNovoPareamento() {
      const resource = this.orcamentoDetalhadoResourceUrl;
      let orcamento = { ...this.novoPareamento };
      orcamento['empresa_id'] = this.clientId;
      orcamento['projeto_id'] = this.projetoId;
      orcamento['valor'] = 0;
      return await resource.save({ ...orcamento }).then((result) => {
          return result;
      });
    },
    doSaveModal() {
      this.salvarNovaPareamento().then((result) => {
        this.dialogRubrica = false;
        this.rubricaPara.rubrica_id = result.rubrica_id;
        this.saveOrcamentoNovoPareamento().then(() => {
          this.saveRemanejamento().then(() => {
            this.getRubricas();
          });
        })
      })
    },
    saveRemanejamentoAndReload() {
      this.saveRemanejamento().then(() => {
        this.getRubricas();
      })
    },
    validaValorSaldo(valor, saldo) {
      if(valor > saldo) {
        this.$notify({
          group: 'geral',
          duration: 5000,
          type: "warn",
          title: "Valores incompatíveis",
          text: 'Valor transferido não pode ultrapassar saldo limite',
        });
        this.isValidValue = false;
        return false;
      }
      return true;
    },
  },
  // watch: {},
}
</script>