var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"teros-elevation"},[_c('v-card-title',{staticClass:"px-5 text-uppercase v-title-cadastro"},[_vm._v(" Remanejamento de rubricas ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',_vm._l((_vm.cols),function(col,index){return _c('inputv',{key:index,staticClass:"px-5 py-0",attrs:{"colSize":col.colSize,"label":col,"opts":_vm.opts,"valid":col.valid},on:{"update:valid":function($event){return _vm.$set(col, "valid", $event)},"changed":function($event){return _vm.atualizaValores($event, col)},"mutate":function($event){return _vm.atualizaSaldo($event)}},model:{value:(_vm.rubricaDe[col.key]),callback:function ($$v) {_vm.$set(_vm.rubricaDe, col.key, $$v)},expression:"rubricaDe[col.key]"}})}),1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',_vm._l((_vm.colsTarget),function(col,index){return _c('inputv',{key:index,staticClass:"px-5 py-0",attrs:{"colSize":col.colSize,"label":col,"opts":_vm.opts,"valid":col.valid},on:{"update:valid":function($event){return _vm.$set(col, "valid", $event)},"changed":function($event){return _vm.atualizaValoresPara($event, col)}}})}),1),_c('v-row',{staticClass:"px-5 justify-end"},[_c('v-dialog',{attrs:{"width":"500","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":""}},'v-btn',attrs,false),on),[_vm._v("+ Adicionar nova rubrica")])]}}]),model:{value:(_vm.dialogRubrica),callback:function ($$v) {_vm.dialogRubrica=$$v},expression:"dialogRubrica"}},[_c('v-card',[_c('v-card-text',[_c('v-item-group',{staticClass:"text-center justify-content-center pt-7",attrs:{"mandatory":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-btn',{style:({
                          opacity: active ? 1 : 0.8,
                          'border-bottom': _vm.currentStep >= 0 ? '5px solid green' : 'none',
                          width: 'auto',
                          padding: '0 0.5rem',
                        }),attrs:{"dense":"","text":"","tile":""},on:{"click":function($event){_vm.currentStep = 0}}},[_vm._v(" 1 - Rubrica ")])]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var active = ref.active;
return [_c('v-btn',{style:({
                          opacity: active ? 1 : 0.8,
                          'border-bottom': _vm.currentStep >= 1 ? '5px solid green' : 'none',
                          width: 'auto',
                          padding: '0 0.5rem',
                        }),attrs:{"dense":"","text":"","tile":""},on:{"click":function($event){_vm.currentStep = 1}}},[_vm._v(" 2 - Pareamento de rubrica ")])]}}])})],1),_c('v-window',{staticClass:"mt-5",model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-window-item',[_c('v-col',[_vm._l((_vm.colsRubrica),function(col,index){return _c('inputv',{key:index,attrs:{"label":col,"opts":_vm.opts},model:{value:(_vm.novaRubrica[col.key]),callback:function ($$v) {_vm.$set(_vm.novaRubrica, col.key, $$v)},expression:"novaRubrica[col.key]"}})}),_c('v-btn',{attrs:{"depressed":"","color":"secondary","dark":""},on:{"click":_vm.salvarNovaRubrica}},[_vm._v("Salvar rubrica")])],2)],1),_c('v-window-item',[_c('v-col',_vm._l((_vm.colsPareamento),function(col,index){return _c('inputv',{key:index,attrs:{"colSize":col.colSize,"label":col,"opts":_vm.opts},model:{value:(_vm.novoPareamento[col.key]),callback:function ($$v) {_vm.$set(_vm.novoPareamento, col.key, $$v)},expression:"novoPareamento[col.key]"}})}),1),_c('v-btn',{attrs:{"depressed":"","color":"secondary","dark":""},on:{"click":_vm.doSaveModal}},[_vm._v("Salvar pareamento")])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"depressed":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('p',[_vm._v("Total comprovado: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaDe.total_realizado)))])]),_c('p',[_vm._v("Total orçado: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaDe.valor_previsto)))])]),_c('p',[_vm._v("Saldo disponível: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency((_vm.rubricaDe.valor_previsto - _vm.rubricaDe.total_realizado))))])]),_c('p',[_vm._v("Valor a ser transferido: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaDe.valor_transferido)))])]),_c('p',[_vm._v("Novo saldo: "),_c('b',{style:({
                    color: _vm.rubricaDe.saldoDisponivelPosTransf < 0 ? 'red' : 'black'
                  })},[_vm._v(" "+_vm._s(_vm.toCurrency(_vm.rubricaDe.saldoDisponivelPosTransf))+" ")])])])],1)],1),_c('v-col',[_c('v-card',[_c('v-card-text',[_c('p',[_vm._v("Total comprovado: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaPara.total_realizado)))])]),_c('p',[_vm._v("Total orçado: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaPara.valor_previsto)))])]),_c('p',[_vm._v("Saldo disponível: "),_c('b',{style:({
                    color: (_vm.rubricaPara.valor_previsto - _vm.rubricaPara.total_realizado) < 0 ? 'red' : 'black'
                  })},[_vm._v(" "+_vm._s(_vm.toCurrency((_vm.rubricaPara.valor_previsto - _vm.rubricaPara.total_realizado)))+" ")])]),_c('p',[_vm._v("Valor a receber: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaPara.valor_transferido)))])]),_c('p',[_vm._v("Novo saldo: "),_c('b',[_vm._v(_vm._s(_vm.toCurrency(_vm.rubricaPara.saldoDisponivelPosTransf)))])])])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-5 py-4 flex-wrap justify-content-end"},[_c('v-btn',{staticClass:"mx-2",staticStyle:{"width":"150px"},attrs:{"color":"secondary","depressed":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('v-btn',{staticStyle:{"width":"200px"},attrs:{"color":"primary","depressed":"","disabled":!_vm.isValidValue},on:{"click":function($event){return _vm.saveRemanejamentoAndReload()}}},[_vm._v("Salvar remanejamento")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }